import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import "../components/CSS/form.css"
import * as L from '../components/Solution/styled'
import * as S from '../components/LandingPage/styled'
import Contact from "../components/LandingPage/contact"
import useTranslations from '../components/useTranslations';


const ContactPage = props => {
  const post = props.data.markdownRemark;
  const {
    retmonTitle,
    retmonDescription,
  } = useTranslations();

  return (
    <>
      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={retmonDescription} />
      <L.Title>{post.frontmatter.title}</L.Title>
      <Contact />
    </>
  );
};

export const query = graphql`
  query Contact($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        page
     
      }
      html
    }
  }
`;

export default ContactPage;

import React from "react"
import useTranslations from '../../components/useTranslations';
import ContactPageForm from '../../templates/contactPageForm'
import { FaLinkedin, FaInstagram, FaTwitter, FaEnvelope } from 'react-icons/fa';
import * as S from "./styled"

const Contact = () => {
  const {
    landingPage
  } = useTranslations();

  return (
    <>
      <S.Row wrap id='#contact'>
        <S.FlexColumn>
          <S.FlexWrapRow>
            <S.BorderedItem>
              <ContactPageForm />
            </S.BorderedItem>
            <S.BorderedItem>
              <S.Header2
                style={{
                  fontSize: '2rem',
                  marginBottom: '1rem'
                }}
              >{landingPage.contactTitle}</S.Header2>
              <div style={{
                display: 'grid',
                gap: '1rem'
              }}>
                <div>
                  <S.FormTitle>{landingPage.contactEmail}</S.FormTitle>
                  <a href='mailto:info@retmon.com'> <S.Text>info@retmon.com</S.Text></a>
                </div>
                <div >
                  <S.FormTitle>{landingPage.contactPhone}</S.FormTitle>
                  <a href='tel:+902163921642'><S.Text>+90 (216) 392 16 42</S.Text></a>
                </div>
                <div>
                  <S.FormTitle>{landingPage.contactAddress}</S.FormTitle>
                  <S.Text
                    style={{
                      width: '90%'
                    }}
                  >{landingPage.contactAddressDetail}</S.Text>
                </div>
              </div>
              <br />
              <div>
                <S.FormTitle>{landingPage.followUs}</S.FormTitle>
                <S.SocialWrapper>
                  <a href='https://www.linkedin.com/company/retmonems' target="_blank" rel="noopener" aria-label='Linkedin Retmon'><FaLinkedin /></a>
                  <a href='https://www.twitter.com/retmonems' target="_blank" rel="noopener" aria-label='Twitter Retmon' ><FaTwitter /></a>
                </S.SocialWrapper>
              </div>
              <br />
              <S.Header2
                style={{
                  fontSize: '2rem',
                  marginBottom: '1rem'
                }}
              >{landingPage.aboutTitle}</S.Header2>
              <S.Text>{landingPage.aboutDesc}
                {landingPage.aboutDesc2}
                <br />
              </S.Text>
              <br />
              <S.FormTitle>{landingPage.support}</S.FormTitle>
              <S.SocialWrapper>
                <a href='mailto:info@retmon.com'>
                  <FaEnvelope />
                </a>
              </S.SocialWrapper>
            </S.BorderedItem>
          </S.FlexWrapRow>
        </S.FlexColumn>
      </S.Row>
    </>
  )
};

export default Contact;

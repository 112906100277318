import React, { useState, useRef, Component } from 'react'
import { navigate } from 'gatsby'
import '../components/CSS/form.css'
import * as S from '../components/Form/styled'
import useTranslations from '../components/useTranslations'
import DocumentDownload from '../components/LandingPage/documentDownload'
// import Recaptcha from "react-google-recaptcha";
import { groupedOptions, formatGroupLabel, type } from "../data/products";
import Select from 'react-select'

const ContactPageForm = props => {

  const { retmonTitle, formlang } = useTranslations()

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  const [validated, setValidated] = useState(false)
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleChangeSelectService = values => {
    setState({ ...state, serviceType: values })
  }

  const handleChangeSelectProducts = values => {
    setState({ ...state, products: values })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }
    setValidated(true)

    if (form.checkValidity() === true)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': ' multipart/form-data' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      }).then(() => navigate(form.getAttribute('action')))
  }

  const {
    name,
    company,
    mail,
    service_details,
    subject,
    product,
    service
  } = useState({})

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };

  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );
  const RequiredField = (
    <span className="requiredField">* {formlang.requiredField}</span>
  )
  return (
    <>
      <div className='contact-page-form-wrapper'>
        <form
          netlify
          validated={validated}
          onSubmit={handleSubmit}
          name="ContactPageForm"
          className='contact-page-form'
          method="post"
          action="/success"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="ContactPageForm" />
          <S.FlexRow>
            <S.Input
              type="text"
              name="Şirket"
              value={company}
              placeholder={formlang.company}
              onChange={handleChange}
            ></S.Input>
          </S.FlexRow>
          <S.FlexRow>
            <S.Input
              type="text"
              required
              name="İsim Soyisim"
              value={name}
              placeholder={formlang.companyAdmin}
              onChange={handleChange}
            ></S.Input>
          </S.FlexRow>
          <S.FlexRow>
            <S.Input
              type="mail"
              required
              name="E-Posta"
              value={mail}
              placeholder={formlang.mail}
              onChange={handleChange}
            ></S.Input>
          </S.FlexRow>
          <S.Select
            value={subject}
            onChange={handleChange} >
            <option value="any">Konu seçiniz..</option>
            <option value="Ürün Değişimi">Ürün Değişim</option>
            <option value="Şikayet/Talep"> Şikayet/Talep</option>
            <option value="Satın Alma">Satın Alma</option>
            <option value="Kargo Takip">Kargo Takip</option>
            <option value="Terminal Değişimi">Terminal Değişim Kampanyası</option>
            <option value="Diğer">Diğer</option>
          </S.Select>
          <S.FormSelectWrapper>
            <S.TextArea
              name="Servis Açıklaması"
              value={service_details}
              spellcheck="false"
              onChange={handleChange}
              placeholder={'Açıklama yazınız.'}
            ></S.TextArea>
          </S.FormSelectWrapper>

          <br />
          <S.RetmonCTAButton right width="12rem" type="submit">
            {validated === true ? <span>{formlang.sending}</span> : formlang.send}
          </S.RetmonCTAButton>
        </form>
      </div>
    </>
  )
}


export default ContactPageForm
